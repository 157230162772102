<template>
  <div class="app-container">
    <div class="items">
      <van-list
        :finished="finished"
        @load="onLoad"
        class="main-body"
        finished-text="没有更多了"
        v-model="loading"
      >
        <template v-for="(item, index) in dataList">
          <div
            class="item"
            @click="
              $router.push({
                name: 'MediaAct',
                query: { article_id: item.article_id },
              })
            "
            :key="index"
          >
            <div class="video-box">
              <van-image
                fit="cover"
                width="100"
                :src="item.cover_img"
                class="img"
              />
              <div class="main">
                <div class="title">
                  {{ item.title }}
                </div>
                <div class="desc">
                  {{ item.desc }}
                </div>
              </div>
            </div>
            <Analytics
              :info="{
                view_count: item.view_count,
                forward_count: item.forward_count,
              }"
            />
          </div>
        </template>
      </van-list>
    </div>
  </div>
</template>
<script>
import Analytics from '../../media/components/analytics.vue'
export default {
  components: {
    Analytics,
  },
  data () {
    return {
      dataList: [],
      total: 0,
      loading: false,
      finished: false,
      query: {
        page: 1,
        type_arr: [4]
      }
    }
  },
  mounted () {
  },
  methods: {
    getDataList () {
      this.loading = true
      this.$axios.post('/wxc/article/lists', this.query).then(res => {
        res.data.list.map(item => {
          this.dataList.push(item)
        })

        this.loading = false
        this.finished = !res.data.isnext
        this.query.page++
      })
    },
    onLoad () {
      setTimeout(() => {
        this.getDataList()
      }, 1000)
    }

  }
}
</script>
<style lang="scss" scoped>
.app-container {
  background-color: rgba(250, 250, 250, 1);
}
.video-box{
  margin-bottom: 5px;
}
.items {
  padding: 10px 15px;
  .item {
    border-bottom: 1px solid #ebedf0;
    &:last-child {
      border: none;
    }
    padding: 10px 0;
    .title {
      font-size: 16px;
      color: #121921;
      font-weight: 600;
      margin-bottom: 5px;
      .tag {
        position: relative;
        top: -2px;
      }
    }
    .desc {
      font-size: 14px;
      color: #6d7383;
    }
    .video-box {
      display: flex;
      .img{
        margin-right: 10px;
        background: #f00;
      }
      .main {
        flex: 1;
      }
    }
  }
}
</style>